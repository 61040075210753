import React, { useEffect, useState, useMemo } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import VideoList from './VideoList';
import { Tabs, Tab, Box } from '@mui/material';
import { Asset } from 'shared-models'; // Assuming this type is available

const ASSETS_COLLECTION = `assets-experimental`;

const styles = {
  liveEventsPage: {
    minHeight: '100vh',
    background: 'linear-gradient(to bottom, #f0f0f0, #e0e0e0)',
    padding: '2rem 1rem',
  },
  contentContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '1.5rem',
    marginBottom: '2rem',
  },
  cardTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '0.5rem',
  },
  cardSubtitle: {
    fontSize: '1.5rem',
    color: '#666',
    marginBottom: '1rem',
  },
  cardDescription: {
    fontSize: '1rem',
    color: '#555',
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  spinner: {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  },
  errorAlert: {
    backgroundColor: '#ffebee',
    border: '1px solid #ef9a9a',
    borderRadius: '4px',
    padding: '1rem',
    marginBottom: '1rem',
  },
  errorTitle: {
    color: '#c62828',
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  errorDescription: {
    color: '#b71c1c',
  },
  searchBar: {
    width: '100%',
    padding: '0.5rem',
    fontSize: '1rem',
    marginBottom: '1rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  lineBreak: {
    margin: '1rem 0',
    borderTop: '1px solid #ccc',
  },
};

interface AssetGroup {
  [source: string]: Asset[];
}

const LoadingSpinner: React.FC = () => (
  <div style={styles.loadingSpinner}>
    <div style={styles.spinner}></div>
  </div>
);

const ErrorAlert: React.FC<{ error: string }> = ({ error }) => (
  <div style={styles.errorAlert}>
    <h3 style={styles.errorTitle}>Error</h3>
    <p style={styles.errorDescription}>{error}</p>
  </div>
);

const LiveEventsPage: React.FC = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('All');

  useEffect(() => {
    const liveEventsRef = collection(db, ASSETS_COLLECTION);
    const q = query(liveEventsRef, orderBy('metadata.date_processed', 'desc'), limit(5000));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedAssets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Asset));
        setAssets(fetchedAssets);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching live events:', err);
        setError('Failed to load live events. Please try again later.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const groupAssetsBySource = useMemo(() => {
    const groups: AssetGroup = { All: [], Uncategorized: [] };
    assets.forEach((asset) => {
      const sourceTopic = asset.annotations?.topics?.find((topic) => topic.startsWith('Source - '));
      if (sourceTopic) {
        const source = sourceTopic.split('Source - ')[1];
        if (!groups[source]) {
          groups[source] = [];
        }
        groups[source].push(asset);
      } else {
        groups['Uncategorized'].push(asset);
      }
      groups['All'].push(asset);
    });
    return groups;
  }, [assets]);

  const tabNames = useMemo(() => {
    const names = ['All', ...Object.keys(groupAssetsBySource).filter(name => name !== 'All' && name !== 'Uncategorized')];
    if (groupAssetsBySource['Uncategorized'].length > 0) {
      names.push('Uncategorized');
    }
    return names;
  }, [groupAssetsBySource]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <div style={styles.liveEventsPage}>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
      <div style={styles.contentContainer}>
        <div style={styles.card}>
          <h1 style={styles.cardTitle}>Live Events</h1>
          <p style={styles.cardDescription}>
            Near real-time AI transcription and analysis of livestreams.
            Page automatically updates when the latest video segment has been processed.
          </p>
        </div>

        <input
          type="text"
          placeholder="Search in transcripts or descriptions..."
          value={searchQuery}
          onChange={handleSearchChange}
          style={styles.searchBar}
        />

        {loading && <LoadingSpinner />}

        {error && <ErrorAlert error={error} />}

        {!loading && !error && (
          <>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="live events tabs">
              {tabNames.map((tabName) => (
                <Tab key={tabName} label={tabName} value={tabName} />
              ))}
            </Tabs>
            <Box mt={2}>
              <VideoList
                assetIds={groupAssetsBySource[selectedTab].map(asset => ({ id: asset.id }))}
                filterType="search"
                filterValue={searchQuery}
              />
            </Box>
          </>
        )}
      </div>
      <div style={styles.lineBreak}></div>
    </div>
  );
};

export default LiveEventsPage;
